<template>
  <div class="container-fluid enterpreneur ">
    <h2 class="header">Entrepreneurship</h2>
    <hr />

    <div class="row pt-4">
      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/enterpreneurship_1.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">Putting Culture and Change at the Forefront of Business </h5>
        </div>
        <div class="row justify-content-between pt-1 pb-3">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by Equity Bank</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://www.equitybank.com/your-business/putting-culture-and-change-at-the-forefront-of-business">Read more</a>
            </div>
          </div>
      </div>

      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/enterpreneuship_2.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">The Marathon of Life:Innovation, Creation, and Implementation</h5>
          </div>
          <div class="row justify-content-between pt-1 pb-3">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by Equity Bank</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://www.equitybank.com/your-business/innovation-creation-and-implementation-in-the-marathon-of-life">Read more</a>
            </div>
          </div>
      </div>

      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/enterpreneurship_3.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">The myth and deception of ‘Smart Cities’ in Africa</h5>
          </div>
        <div class="row justify-content-between pt-1 pb-3">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by Tyn Magazine</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://www.tynmagazine.com/the-myth-and-deception-of-smart-cities-in-africa/">Read more</a>
            </div>
          </div>
      </div>

    </div>

    <div class="row pt-4">
      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/rsz_21rsz_1enterpreneuship_4.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">Businesses must strive to comply despite ever-changing regulatory landscape</h5>
          </div>
          <div class="row justify-content-between pt-1 pb-3 ">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by NguvuAfrica</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://nguvu.africa/business-survival/">Read more</a>
            </div>
          </div>
      </div>
      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/milken.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">Milken Institute and Motsepe Foundation Launch Technology Prize Program to Propel Innovation and Entrepreneurship in Africa</h5>
          </div>
          <div class="row justify-content-between pt-1 pb-3">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by BusinessWire</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://www.businesswire.com/news/home/20210428005096/en/Milken-Institute-and-Motsepe-Foundation-Launch-Technology-Prize-Program-to-Propel-Innovation-and-Entrepreneurship-in-Africa">Read more</a>
            </div>
          </div>
      </div>
    </div>

    <!-- <p class="pt-5 more">
      View More
      <a class="view_more" href="https://africacdc.org/">
        <i class="fas fa-chevron-right"></i>
        <i class="fas fa-chevron-right"></i>
      </a>
    </p> -->
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.enterpreneur{
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}
.img-responsive {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  color:#000;
}
hr {
  border: 1px solid #000;
}

.more {
  color: #8c8c8c;
}
.view_more{
  text-decoration: none;
  background-color: transparent;
  color: #8c8c8c;
}
.iconify {
  border: 1px solid #8c8c8c;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:7px 10px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
@media screen and (max-width: 1100px) {
  .enterpreneur{
  padding: 4em 5%;
}
}
@media screen and (max-width: 768px) {
  .news{
    margin-bottom: 5px;
  }
  div.card_container:not(:last-of-type){
    margin-bottom:3em;
  }
}
</style>