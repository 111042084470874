<template>
  <!-- <div
    id="carouselExampleIndicators"
    class="carousel slide pb-5"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="../../assets/new/Home.jpg"
          alt="First slide"
        />
        <div class="carousel-caption d-none d-md-block">
          <h1 class="display-3">AfCFTA vision's <br> contest</h1>
          <p>
            Africa's foremost leaders are challenging you to submit your bold
            ideas to the
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="../../assets/new/African-common.jpg"
          alt="Second slide"
        />
        <div class="carousel-caption d-none d-md-block">
          <h1 class="display-3">AfCFTA vision's <br> contest</h1>
          <p>
            Africa's foremost leaders are challenging you to submit your bold
            ideas to the
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="../../assets/new/African-common.jpg"
          alt="Third slide"
        />
        <div class="carousel-caption d-none d-md-block">
           <h1 class="display-3">AfCFTA vision's <br> contest</h1>
          <p>
            Africa's foremost leaders are challenging you to submit your bold
            ideas to the
          </p>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> -->

  <div class="container-fluid slider">
    <div class="row justify-content-center hero_content">
      <div class="col-md-8 ">
        <h2 class="lead">Debating and</h2>
        <h2 class="lead">Shaping AfCFTA</h2>
        <p class="virtual-home">Virtual home of AfCFTA Movement</p>
      </div>
      <div class="col-md-4 mt-5">
        <div class="trending-debates">
          <h3>Trending Debate</h3>
          <p>African's free trade agreement:</p>
          <p>Curse or blessing?</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.slider {
  width:100%;
  background-image: url('../../assets/new/Home.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 8em 12%;
  color:#fff;
}
.slider h2{
  font-weight: bold;
  font-size: 4em;
}
.virtual-home{
  padding-top: 2em;
  font-style: italic;
  opacity: 0.7;
}
.trending-debates{
  background-color: blue;
  opacity: 0.6;
  padding:10px;
}
.trending-debates h3{
  font-weight: bolder;
  font-size: 1em;
  padding-bottom: 1.5em;
}
.trending-debates p{
  line-height: 5px;
}
.hero_content{
  margin-top:10em;
}
.carousel .carousel-item {
  height: 500px;
}
.carousel-item img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;

}

@media screen and (max-width: 1100px) {
  .slider{
      padding-right:5% !important;
      padding-left: 5% !important;
  }
}

@media screen and (max-width: 768px) {
.slider h2{
  font-weight: bold;
  font-size: 2em;
}
.slider {
  padding:4em;
}
.trending-debates{
      width:250px;
  }
}
</style>