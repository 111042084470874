<template>
  <div class="container-fluid upcoming_events">
    <div class="row">
      <div class="col-md-7">
        <h2 class="header">Upcoming Events</h2>
        <hr />
        <div class="row events py-4">
          <div class="col-md-6">
            <img
              src="../../assets/new/entrep.jpg"
              class="img-fluid py-3"
              alt=""
            />
          </div>
          <div class="col-md-6 content">
            <h4>1st International Conference on Public Health in Africa</h4>
            <p>
              02 February 2021, Addis Ababa, Ethiopia — The Africa Centres for
              Disease Control and Prevention (Africa CDC) is announcing...
            </p>
            <h5>14 May 2021 Addis Ababa, Ethiopia</h5>
            <small>Languages: English, French</small>
          </div>
        </div>
        <div class="row  events py-4">
          <div class="col-md-6">
            <img
              src="../../assets/new/entrep.jpg"
              class="img-fluid py-3"
              alt=""
            />
          </div>
          <div class="col-md-6 content">
            <h4>1st International Conference on Public Health in Africa</h4>
            <p>
              02 February 2021, Addis Ababa, Ethiopia — The Africa Centres for
              Disease Control and Prevention (Africa CDC) is announcing...
            </p>
            <h5>14 May 2021 Addis Ababa, Ethiopia</h5>
            <small>Languages: English, French</small>
          </div>
        </div>

        <div class="row events py-4">
          <div class="col-md-6">
            <img
              src="../../assets/new/entrep.jpg"
              class="img-fluid py-3"
              alt=""
            />
          </div>
          <div class="col-md-6 content">
            <h4>1st International Conference on Public Health in Africa</h4>
            <p>
              02 February 2021, Addis Ababa, Ethiopia — The Africa Centres for
              Disease Control and Prevention (Africa CDC) is announcing...
            </p>
            <h5>14 May 2021 Addis Ababa, Ethiopia</h5>
            <small>Languages: English, French</small>
          </div>

          
        </div>
      </div>
      <div class="col-md-4 events offset-md-1">
        <h3><i>Other Issues</i></h3>
        <hr />
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
        <hr>
         <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
        <hr>
         <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
        <hr>
         <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
        <hr>
         <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
        <hr>
         <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard
        </p>
      </div>
     
        <div class="col-md-12 text-center my-5">
              <button class="btn view_more">View more</button>
        </div>
     
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.upcoming_events{
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 7em;
}
.view_more{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
}
.view_more:focus, .view_more:active, .view_more:hover{
  outline:none;
  background-color:#dC3545;;
  color:#fff;
  border:1px solid  #dC3545;;
}
hr {
  border: 1px solid #000;
}

h3 {
  color: #000;
}
.more {
  color: #8c8c8c;
}

.iconify {
  border: 1px solid #8c8c8c;
}

@media screen and  (max-width: 1100px) { 
  .upcoming_events{
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4em;
}
 }

@media screen and (max-width: 768px) {
  .events{
    margin-bottom: 15px;
    padding: 10px;
  }
}
</style>