<template>
  <div>
    <nav-bar />
    <slider />
    <health />
    <entreprenuer />
    <trending-debates />
    <!-- <upcoming-events /> -->
    <Footer/>
  </div>
</template>

<script>
import Entreprenuer from "../components/New/Entreprenuer.vue";
import Footer from '../components/New/Footer';
import Health from "../components/New/Health.vue";
import NavBar from "../components/New/NavBar.vue";
import Slider from "../components/New/Slider.vue";
import TrendingDebates from "../components/New/TrendingDebates.vue";
import UpcomingEvents from "../components/New/UpcomingEvents.vue";

export default {
  components: {
    NavBar,
    Slider,
    Health,
    Entreprenuer,
    TrendingDebates,
    UpcomingEvents,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
</style>