<template>
  <div class="container-fluid health">
    <h2 class="header">Health</h2>
    <hr />

    <div class="row pt-4 ">
      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/health.jpg"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">CEPI and the African Union join forces to boost African vaccine R&D and manufacturing</h5>
          </div>
          
            <div class="row justify-content-center pt-1  publish">
              <div class="col-7 col-md-7 col-lg-7">
                <small>Published by Africa CDC</small>
              </div>
              <div class="col-5 col-md-5 col-lg-5 text-right">
                <a target="_blank" class="download_btn" href="https://issafrica.org/iss-today/vaccine-nationalism-and-african-government-deficiencies-hinder-roll-out">Read more</a>
              </div>
            </div>
          
      </div>

      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/health_1.png"
             class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">African Union and Africa CDC launches Partnerships for African Vaccine Manufacturing (PAVM), framework to achieve it and signs 2 MoUs</h5>
        </div>
         <div class="row justify-content-center pt-1">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by Africa CDC</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a class="download_btn"  target="_blank" href="https://africacdc.org/news-item/african-union-and-africa-cdc-launches-partnerships-for-african-vaccine-manufacturing-pavm-framework-to-achieve-it-and-signs-2-mous/">Read more</a>
            </div>
          </div>
      </div>

      <div class="col-md-4 card_container">
          <div class="news">
            <img
              src="../../assets/new/AfricaCDC_COVID-19_fb.jpg"
             class="img-responsive"
              alt=""
            />
            <h5 class="pt-4">Africa is struggling to meet its vaccination targets for reasons that aren’t as simple as they might seem.</h5>
          </div>
          <div class="row justify-content-between pt-1 ">
            <div class="col-7 col-md-7 col-lg-7">
              <small>Published by Institute for security studies</small>
            </div>
            <div class="col-5 col-md-5 col-lg-5 text-right">
              <a target="_blank" class="download_btn" href="https://issafrica.org/iss-today/vaccine-nationalism-and-african-government-deficiencies-hinder-roll-out">Read more</a>
            </div>
          </div>
      </div>

    </div>

    <!-- <p class="pt-5 more">
      View More
      <a class="view_more" href="https://africacdc.org/">
        <i class="fas fa-chevron-right"></i>
        <i class="fas fa-chevron-right"></i>
      </a>
    </p> -->

  </div>
</template>

<script>
export default {};
</script>

<style  scoped>

.health{
  padding: 7em 12%;
}
hr {
  border: 1px solid #000;
}
.img-responsive {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  color:#000;
}
.more {
  color: #8c8c8c;
}
.view_more{
  text-decoration: none;
  background-color: transparent;
  color: #8c8c8c;
}
.iconify {
  border: 1px solid #8c8c8c;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:7px 10px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
@media screen and (max-width: 1100px) {
  .health{
  padding: 4em  5% 0 5%;
}
}
@media screen and (max-width: 768px) {
  .news{
    margin-bottom: 5px;
    /* padding: 10px; */
  }
  div.card_container:not(:last-of-type){
    margin-bottom:3em;
  }
}
</style> 